.billboard {
  position: relative;

  //@include retina() {
  //  background-image: url('../images/bg/bg-billboard.jpg?as=webp&');
  //}
  //
  //background-color: #000000;
  //background-image: url('../images/bg/bg-billboard.jpg?as=webp&width=1920');
  //background-repeat: no-repeat;
  //background-position: top;
  //background-size: contain;
  ////background-size: auto 100%;
}

.billboard__image {
  position: absolute;
  top: 0;

  display: inline-block;

  width: 100%;
  height: 100vh;
  min-height: 688rem;

  @media screen and (min-width: $sm) {
    opacity: 0;
  }

  img, picture {
    height: 100%;

    object-fit: contain;
    //object-position: top;
    object-position: center;
    margin-left: auto;
  }
}

.billboard__description {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100vh;
  max-height: 900rem;
  left: 40rem;

  width: 489rem;

  svg {
    position: relative;
    top: 68%;
  }

  @media screen and (min-width: $sm) {
    opacity: 0;
  }

  img, picture {
    width: 100%;
  }
}

.billboard__freebet-link {
  cursor: pointer;
  transition: 0.3s ease-in;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

@media screen and (max-width: $sm) {
  .billboard {
    display: none;
  }
}
