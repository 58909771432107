.promo {
  @include retina() {
    background-image: url('../images/bg/bg-billboard.jpg?as=webp&');
  }

  position: absolute;

  display: flex;
  overflow: hidden;

  width: 100%;
  height: 100%;

  color: #ffffff;
  background-color: #1f1f1f;
  background-image: url('../images/bg/bg-billboard.jpg?as=webp&width=1920');
  background-position: bottom left;
  background-size: auto 100%;

  font-size: 16rem;
}

.promo__billboard {
  flex-grow: 1;
}

.promo__aside {
  flex-basis: 43%;
}

@media screen and (max-width: $sm) {
  .promo {
    @include retina() {
      background-image: url('../images/bg/bg-mobile.jpg?as=webp&');
    }

    background-image: url('../images/bg/bg-mobile.jpg?as=webp&width=1920');
    background-size: cover;
    background-position: top;
    position: relative;

    padding: 60rem 20rem 38rem;

    height: auto;
  }

  .promo__aside {
    flex-basis: 100%;
  }
}
